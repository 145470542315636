.blogItem-wrap {
    display: flex;
    flex-direction: column;
}

.blogItem-cover {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: .5rem;
}

.blogItem-wrap h3 {
    margin: 0.5rem 0 1rem 0;
    flex: 1;
} 

.blogItem-desc {
    position: relative;
    max-height: 40px;
    overflow: hidden;
    font-size: 0.8rem;
    padding-right: 0.6rem;
    color: #a9a9a9;
    text-align: left;
}
.blogItem-desc::before {
    position: absolute;
    content: '...';
    bottom: 0;
    right: 0;
}
.blogItem-wrap footer {
    display: flex;
    align-items: center;
    margin-top: .5rem;
    justify-content: space-between;
}
.blogItem-link {
    text-decoration: none;
    color: #3c3269;
}

.blogItem-author {
    display: flex;
    align-items: center;
}
.blogItem-author img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
}
.blogItem-author p {
    font-size: 0.6rem;
    font-weight: 600;
    color: #a9a9a9;
}
