.maingallery {
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.my-3 {
    color: #040453;
    padding-top: 3rem;
    padding-bottom: 0rem;
}

