/* Any change made in this css file should be made respectively
in BoysConnect19 style.css */

.container-g {
  width: 90%;
  margin: 1rem auto;
}

.gallery__item {
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 1px 2px 4px grey;
  height: 105px;
}

.gallery__img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 3px;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: .7rem;
}

@media (min-width: 600px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(18, 5.5vw);
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
  }

  .gallery__item {
    height: 100%;
  }

  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    /** Alternative Syntax **/
    /* grid-column: 1 / span 2;  */
    /* grid-row: 1 / span 2; */
  }

  .gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;

    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }

  .gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 7;

    /** Alternative Syntax **/
    /* grid-column: 5 / span 4;
      grid-row: 1 / span 5; */
  }

  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 7;

    /** Alternative Syntax **/
    /* grid-column: 1 / span 4;  */
    /* grid-row: 3 / span 3; */
  }

  .gallery__item--5 {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 7;
    grid-row-end: 12;

    /** Alternative Syntax **/
    /* grid-column: 1 / span 4; */
    /* grid-row: 6 / span 3; */
  }

  .gallery__item--6 {
    grid-column-start: 6;
    grid-column-end: 11;
    grid-row-start: 7;
    grid-row-end: 12;

    /** Alternative Syntax **/
    /* grid-column: 5 / span 4; */
    /* grid-row: 6 / span 3; */
  }

  .gallery__item--7 {
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .gallery__item--8 {
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 5;
    grid-row-end: 7;
  }

  .gallery__item--9 {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 12;
    grid-row-end: 19;
  }
}

@media (max-width: 768) {}