/* .main {
  background-color: lightgrey;

} */

.wrapper {
  display: flex;
  background-color: lightgrey;
}

.two {
  background-color: #286090;
  position: relative;
  padding: 5px 10px 0 40px;
  width: 100%;
  min-height: 500px;
  height: 100vh;
}

.add-button {
  position: absolute;
  bottom: 20px;
  right: 45px;
}

.faCirclePlus {
  font-size: 45px;
  background-color: #286090;
}

@media (min-width: 750px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(500px, auto);
    background-color: lightgrey;
    padding: 0.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .two {
    grid-column: 2/6;
    background-color: #286090;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    /* min-height: 500px;
    height: 80vh; */
  }

  .faCirclePlus {
    font-size: 60px;
    background-color: #286090;
  }
}
