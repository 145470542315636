.home-header {
    text-align: center;    
}

.header-bg {
    position: relative;
    background-size: cover;
    background-blend-mode: overlay;
    background-image: url("../../../../public/myImages/photo-tree_2.webp");
    background-color: #24243f;
    background-repeat: no-repeat;
    background-position: center center;
    height: 85vh; 
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

.home-header h2 {
    /* color: #725450; */
    color :antiquewhite;
    font-size: 2rem;
}
.home-header h1 {
    font-size: 3rem;
    /* color: #0f52ba; */
    color :red;
    margin-bottom: 1rem;    
}
.home-header h1 span {
    /* color: #b0c4de */
    color :red;
}
.home-header p {
    /* color: #666464; */
    color :antiquewhite;
    font-weight: 500;
}

.hd-w-25 {
    width: 25%;
}

@media (max-width: 1250px) {
    .hd-w-25 {
        width:55%;
    }
}

@media (max-width: 600px) {
    .header-bg {
        padding-top: 10vh;
    }
    .home-header h2 {       
        font-size: 1.3rem;
    }
    .home-header h1 {
        font-size: 2.1rem; 
    }
    .home-header p {
        font-size: .9rem;
    }
    .hd-w-25 {
        width: 80%;
    }
}