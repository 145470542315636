.space-text {
  word-spacing: 1em;
  font-size: 1rem;
}

.header-container {
  position: relative;
  z-index: 2;
}

.home-header-bg {
  /* background-size: cover;
    background-image: url("../../../public/myImages/omokupro2.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden; */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 2;
}

.wytp-z-index {
  position: absolute;
  top: 91vh;
  right: 10px;
  z-index: 4;
}

.wytp-button {
  color: whitesmoke;
  background-color: red; 
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  /* border: rgb(212, 137, 39) solid; */
  font-size: 1.5vw;
  text-decoration: none;
}
.wytp-button:hover {
  background-color: #fac226;
  color: #040453;
}

.button {
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  border: #fac226 solid;
  font-size: 1.5vw;
}

.yel-col {
  background-color: #fac226;
  color: white;
}

.button:hover {
  background-color: #fac226;
  color: #040453;
}


.bg-img-1, .bg-img-2, .bg-img-3, .bg-img-4, .bg-img-5 {
  background-size: cover;  
  background-image: url("../../../public/myImages/omokupro1.webp");
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.bg-img-2 {
  background-image: url("../../../public/myImages/ryt-edited-pictures-3.webp");
}

.bg-img-3 {
  background-image: url("../../../public/myImages/ryt-edited-pictures-4.webp");
}

.bg-img-4 {
  background-image: url("../../../public/myImages/ryt-edited-pictures-2.webp");
}

@media (max-width: 600px) {
  .carousel-header-bg {
    height: 95vh !important;
  }

  .wytp-z-index {
    top: 87vh;
  }

  .button {
    font-size: 2.3vw;
  }

  .wytp-button {
    font-size: 2.3vw;
  }

  .space-text {
    word-spacing: 1em;
    font-size: .75rem;
    padding-top: 10px;
  }

}
/* .button {
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  border: rgb(212, 137, 39) solid;
  font-size: 1.5vw;
} */