@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@600&display=swap");

.event-backgound {
  background-image: linear-gradient(to left top, #040453, #ff0000 90%);
  /* background: url('../../../public/myImages/images\ \(1\).jpeg');
    background-repeat: no-repeat;
    background-size: cover; */
  /* height: 100vh; */
}
/* linear-gradient(to right bottom, #040453, #ff0000 90%); */

.event-content {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  align-items: center;
  height: 83vh;
}

.styled {
  font-family: "Dancing Script", cursive;
}

.time-styled {
  font-family: "Source Code Pro", monospace;
}

.event-img-div {
  width: 65%;
}

.event-img {
  width: 70%;
  max-width: 550px;
  border-radius: 20px;
}

.dis-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

.dis-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .event-img-div {
    width: 90%;
  }
}

@media (max-width: 950px) {
  .event-img-div {
    width: 100%;
  }

  .event-content {
    width: 95%;
  }
}

@media (max-width: 650px) {
  .event-content {
    display: block;
    width: 95%;
  }

  .event-img-div {
    padding: 15px 0;
  }

  .event-img {
    width: 60%;
  }

  .time-left {
    padding: 25px 0 15px 15px;
    font-size: small !important;
  }

  .event-text {
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 10px;
  }

  .dis-3 {
    font-size: calc(1.2rem + 1.0vw);
    font-weight: 200;
    line-height: 0.8;
    letter-spacing: 0.2px;
  }

  .dis-5 {
    font-size: calc(1.0rem + 0.7vw);
    font-weight: 100;
    line-height: 0.8;
  }
}

@media (max-width: 500px) {
  .event-img {
    width: 70%;
  }
  .time-left {
    padding: 25px 0 15px 15px;    
  }
}
