.comment__border-radius {
    border-radius: 15px;
}

.comment__time-break {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media (max-width: 430px) {
    .comment__time-break {
        display: block;
    }
}