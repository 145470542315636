@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

button,
input {
  font-family: "Montserrat", sans-serif;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinner {
  -moz-appearance: textfield;
  width: 90%;
}

.container-2 {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 10em 0;
}

.container-3 {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 4em 0;
}

.blog-body {
  background-color: rgba(238, 29, 14, 0.027);
}

.App {
  text-align: center;
}

.navback {
  background-color: rgba(13, 42, 230, 0.1);
}

.the-blur {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 60px;
  margin-bottom: 20px;
  background: #04045328;
  backdrop-filter: blur(5px);
  z-index: -1;
}

.overlay {
  z-index: 1;
  position: absolute;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.glow:hover {
  color: #fac226;
  text-shadow: 0 0 15px #fac226, 0 0 10px #fac226, 0 0 15px #fcd25e,
    0 0 18px #fcd25e;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .view {
  position: relative;
  background-size: cover;
  background-image: url("../public/myImages/omokupro2.webp");
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
} */

.text-right {
  text-align: left;
  font-weight: 500;
  font-size: 1.2rem;
}

.text {
  font-size: 3vw;
}

.header-text {
  font-size: 3vw;
}

.align-left {
  text-align: left;
}

.white-text {
  color: whitesmoke;
}

.bg-red {
  background-color: red;
}

.nav-main {
  margin: 0;
  padding: 0 20px 0 20px;
}

.nav-content {
  padding-top: 20%;
  color: whitesmoke;
  width: 100%;
  margin: auto;
}

.paragraph {
  font-size: 1.5vw;
}

.button {
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  border: #fac226 solid;
  font-size: 1.5vw;
}

.button:hover {
  background-color: #fac226;
  color: #040453;
}

.wytp {
  display: flex;
  justify-content: flex-end;
  margin-top: 19vh;
}

.wytp button {
  background-color: #c91919;
  border-radius: 30px;
  font-size: 1.5vw;
}

.space-word {
  word-spacing: 0.5em;
  padding-top: 20px;
  font-size: 20px;
}

.btn-1 {
  color: white;
  background-color: #fac226;
  padding: 10px 35px 10px 35px;
  border-radius: 30px;
  border: #fac226;
  font-size: 1rem;
  transition: background-color 1s ease;
}

.btn-1:hover {
  background-color: #c91919;
  /* box-shadow: 0 0 5px 7px #fac126c0 !important; */
}

#service {
  color: #fff;
  background-color: #040453;
}

#service h1 {
  color: #fff;
}

.service-container {
  background-color: #040453;
}

.img-hand {
  max-width: 100%;
  width: 50vw;
}

.service-text {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  color: #fff;
  font-size: 1.5rem;
  padding-top: 15vh;
}

@media (max-width: 992px) {
  .service-text {
    padding-top: 2.5vh;
  }
}

.service-text span {
  color: #040453;
}

.section-1 {
  width: 65%;
  line-height: 45px;
  font-weight: 500;
  padding-bottom: 30px;
}

.section-2 {
  background-color: #040453;
  max-height: 60vh;
  padding: 50px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.section-3 {
  background-color: rgba(13, 42, 230, 0.5);
  background-blend-mode: color;
  background-image: url("../public/myImages/youthphoto.webp");
  background-size: cover;

  min-height: 45vh;
  color: #fff;
  padding: 70px 8% 30px 8%;
}

.section-3 h2 {
  font-size: 3rem;
}

.section-4 {
  background-color: #040453;
  color: rgb(222, 222, 222);
  padding: 70px 15%;
  margin: 35px 0 25px 0;
}

.section-5 {
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url("../public/myImages/sky.webp");
  padding: 60px 0 60px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

#icons {
  display: flex;
  justify-content: flex-end;
}

.font-awesome {
  padding-right: 15px;
  font-size: 20px;
}

.button-bg {
  margin: 30px 0 10px 0;
}

.my-mission {
  font-size: 18px;
  font-weight: 500;
  padding: 20px 0 20px 0;
}

.my-mission h3 {
  color: #040453;
}

.kc-mission {
  padding: 10px 0 50px 0;
}

.underline {
  height: 5px;
  width: 24rem;
  background-color: rgb(223, 26, 26);
  margin-top: 2px;
  margin-bottom: 30px;
  border-radius: 30px;
}

.underline-1 {
  height: 5px;
  width: 10rem;
  background-color: rgb(223, 26, 26);
  margin-top: 0px;
  margin-bottom: 30px;
  border-radius: 30px;
}

.underline-2 {
  height: 4px;
  width: 50%;
  background-color: rgb(223, 26, 26);
  margin-top: 0px;
  margin-bottom: 30px;
  border-radius: 30px;
}

.matters-arising {
  padding: 50px 0 0px 0;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-left > .button-bg {
  margin-top: 5px;
}

.services {
  background-color: #1c2331;
  color: #fff;
}

.footer {
  background-color: #040453;
  padding: 30px 0 10px 0;
}

.footer-row1 {
  margin-right: 50px;
}

.footer > div > .flex-left {
  padding: 50px 0 0 25px;
}

.li-none {
  list-style-type: none;
}



.text-link {
  color: inherit;
  text-decoration: none;
}

.testimony {
  width: 60%;
  line-height: 45px;
  font-weight: 500;
  padding-bottom: 30px;
}

.form {
  width: 100%;
  align-items: center;
}

.input-name {
  height: 50px;
  border-radius: 40px;
}

.input-email {
  height: 50px;
  border-radius: 40px;
  margin: 20px 0 20px 0;
}

.form-update {
  margin-left: 30px;
  color: #fff;
  padding-top: 30px;
  text-align: left;
}

.input-button {
  height: 50px;
  color: #fff;
  background-color: #fac226;
  border-radius: 10px;
  width: 100%;
  font-size: 25px;
  padding-bottom: 20px;
}

.e-book {
  padding: 60px 0 60px 0;
}

.book-header {
  font-size: 2rem;
  padding-top: 23px;
  color: #040453;
}

/* .card-1 {
  position: relative; */
/* For positioning the pseudo-element */
/* box-shadow: 0 0 10px 0 rgba(59, 7, 245, 0.39);
} */

/* .card-1::before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 10px 50px 0 rgba(59, 7, 245, 0.5); */

/* Hidden by default. */
/* opacity: 0;
  transition: opacity 500ms;
} */

/* .card-1:hover::before { */
/* Show the pseudo-element on hover. */
/* opacity: 1;
} */

.rainbow {
  font-size: 25px;
  font-weight: 500;
}

.container-1 {
  display: flex;
}

.matters-clip {
  max-width: 100%;
  object-fit: cover;
  /* width: 100%; */
  overflow: hidden;
}

.ebook-img {
  max-width: fit-content;
  max-height: fit-content;
  width: 65%;
}

#contact-us {
  padding-top: 5rem !important;
}

.ryt-logo {
  height: 3.1vw;
}

.my-testimony {
  max-height: 100vh;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 800px) and (max-width: 850px) {
  .navbar:not(.top-nav-collapse) {
    background: #040453 !important;
  }
}

@media screen and (max-width: 750px) {
  .view {
    height: 100vh;
  }

  .nav-content {
    padding-top: 40vh;
  }

  .wytp {
    margin-top: 26vh;
  }

  .testimony {
    font-size: 2vw;
    width: 80%;
    line-height: 35px;
  }

  .matters-clip {
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
  }

  .flex-row {
    display: block;
  }
}

@media (max-width: 990px) {
  .flex-row {
    display: block;
  }
}

@media (min-width: 990px) {
  .w-matters-clip {
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 749px) {
  .view {
    height: 96vh;
  }

  .nav-content {
    padding-top: 40vh;
  }

  .header-text {
    font-size: 6vw;
  }

  .paragraph {
    font-size: 2.5vw;
  }

  .button {
    font-size: 2.5vw;
  }

  .space-word {
    word-spacing: 1em;
    padding-top: 20px;
    font-size: 1rem;
  }

  .wytp {
    margin-top: 5rem;
  }

  .wytp button {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    font-size: 2.5vw;
  }

  .container-1 {
    display: grid;
  }

  .testimony {
    font-size: 3vw;
    width: 85%;
    line-height: 35px;
  }
}

@media (max-width: 600px) {
  .navbar:not(.top-nav-collapse) {
    background: #0b0b274f !important;
  }

  .ryt-logo {
    height: 30px;
  }

  .view {
    height: 90vh;
  }

  .header-text {
    font-size: 6vw;
  }

  .paragraph {
    font-size: 3vw;
  }

  .text-right {
    text-align: left;
    font-weight: 500;
    font-size: 0.8rem;
  }

  .underline {
    width: 12rem;
  }

  .button {
    font-size: 3vw;
  }

  .wytp {
    display: flex;
    justify-content: flex-end;
    margin-top: 8rem;
  }

  .wytp button {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    font-size: 2.5vw;
  }

  .nav-content {
    padding-top: 40vh;
  }

  .mb-btn-size {
    font-size: small !important;
  }

  .section-1 {
    width: 80%;
    line-height: 30px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  .sec-2 {
    font-size: 1.4rem;
  }

  .section-3 h2 {
    font-size: 2rem;
  }

  .section-3 p {
    font-size: 0.8rem;
    text-align: center;
  }

  .underline-2 {
    width: 60%;
  }

  .service-text {
    font-size: 1.2rem;
  }

  .matters-clip {
    box-sizing: border-box;
    width: 70%;
    padding-bottom: 20px;
    margin: auto 0;
  }

  .section-3 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: center center;
  }

  .matters-arising {
    padding: 10px 0 20px 0;
  }

  .book-header {
    font-size: 1rem;
    padding-top: 23px;
    color: #040453;
  }

  .button-bg {
    margin: 30px 0 25px 0;
  }

  .btn-1 {
    font-size: 0.8rem;
  }

  .img-radius {
    height: 150%;
  }

  .testimony {
    font-size: 3vw;
    width: 80%;
    line-height: 35px;
  }

  .form {
    width: 70%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
  }

  .form-update {
    margin-right: 40px;
    color: #fff;
    padding-top: 30px;
    text-align: left;
  }

  .book-container {
    padding-left: 30px;
  }

  #logo {
    height: 6vw;
    width: 15vw;
  }

  .navbar {
    background-color: #1c2331;
  }

  .space-word {
    font-size: 2.2vw;
  }

  .rainbow {
    font-size: 3vw;
  }

  .container-1 {
    display: grid;
  }

  #contact-us {
    padding-top: 5rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.App-header {
  background-color: white;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: rgb(8, 8, 8);
}

.App-header h1 {
  color: #040453;
}

.who {
  width: 75%;
  padding: 15px 0 0 0;
  margin: auto;
}

.meet-our-team > div:nth-of-type(2) {
  width: 85%;
  /* margin-bottom: 120px; */
}

.meet-our-team > div:nth-of-type(3) {
  width: 85%;
  margin-bottom: 120px;
}

.meet-our-team > div:nth-of-type(2) > div:nth-of-type(1) {
  width: 48%;
}

.meet-our-team > div:nth-of-type(2) > div:nth-of-type(1) > img {
  max-width: 20rem;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 10px;
}

.meet-our-team > div:nth-of-type(2) > div:nth-of-type(2) {
  width: 47%;
}

.vmm {
  background-color: #f8f9fa;
  /* border: #dee2e6 1px solid; */
}

.vmm:hover {
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: ease-in-out 0.3s;
}

.each-aim:hover {
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.clicked-aim {
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.slide-out-element {
  overflow: hidden;
	opacity:0;
	position:absolute;
	left:-999em;
	box-sizing:border-box;
	width:100%;
	top:100%;
	transform:translateY(-100%);
	transition:transform 1s ease, opacity 1s ease, top 0s ease 1s, left 0s ease 1s;
}

.slide-out-element.visible {
  transform:translateY(0%);
	opacity:1;
	left:0;
	transition:transform 1s ease, opacity 1s ease;
}

.aim-div{
  display: flex;
}

.aim-head{
  width: 25%;
}

.aim-body-mob{
  display: none;
}

/* About Us page who class media query */
@media (max-width: 850px) {
  .meet-our-team > div:nth-of-type(2) {
    display: block !important;
  }

  .meet-our-team > div:nth-of-type(2) > div:nth-of-type(2) {
    width: 100%;
  }

  .meet-our-team > div:nth-of-type(2) > div:nth-of-type(1) > img {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .who {
    width: 80%;
  }

  .meet-our-team > div:nth-of-type(2) > div {
    margin: auto;
  }

  .meet-our-team > div:nth-of-type(2) > div:nth-of-type(1) {
    width: 100%;
  }

  .meet-our-team > div:nth-of-type(2) > div:nth-of-type(1) > img {
    width: 100%;
    margin: auto;
  }

  .meet-our-team > div:nth-of-type(2) > div:nth-of-type(2) {
    width: 100%;
    margin-top: 2rem;
  }

  .about-us > h1:nth-of-type(1) {
    font-size: 300px;
  }
}

.vision-sec {
  padding-left: 5em;
}

/* Fortune Styling Starts here */

.App {
  text-align: center;
  width: 100%;
}

.home {
  background-color: red;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
}

.nav-bar ul li {
  align-items: flex-start;
}

.grey-col {
  background-color: #dedede;
}

.link-col {
  color: black;
  text-decoration: none;
}

.red-line-contact {
  border: 2px solid red;
  border-radius: 2px;
  width: 60%;
  background-color: red;
  margin: auto;
}

.red-line-short {
  border: 2px solid red;
  border-radius: 2px;
  width: 5rem;
  background-color: red;
  margin: auto;
}

.red-line-left {
  border: 2px solid red;
  border-radius: 2px;
  width: 5rem;
  background-color: red;
  margin-left: 0;
}

.just-space {
  justify-content: space-between;
}

.add-length {
  width: 85%;
}

.yel-col {
  background-color: #fac226;
  color: white;
}

.yel-text {
  color: #fac226;
  text-decoration: none;
}

.red-text {
  color: red;
}

.blue-text {
  color: #040453;
}

.name-width {
  width: 40.4%;
}

.post-33 {
  width: 30%;
}

.about-us-bg {
  position: relative;
  background-size: cover;
  background-image: url("../public/myImages/boyconnect1.webp");
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-bg::after,
.meet-our-team::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-right: 50vw solid #fff;
  border-left: 50vw solid #fff;
  border-top: 100px solid transparent;
}

.meet-our-team {
  position: relative;
  background-size: cover;
  background-image: url("../public/myImages/dar-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.meet-our-team::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-right: 50vw solid transparent;
  border-left: 50vw solid transparent;
  border-top: 100px solid white;
}

.mot-top {
  margin-top: 170px;
}

.mtop-6 {
  margin-top: 60.5px;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  filter: invert(100%);
}

.carousel-test {
  max-height: 25rem !important;
  min-height: 25rem !important;
}

.carousel-item-test {
  min-height: 0rem !important;
  max-height: 0rem !important;
}

.carousel-inner-test {
  min-height: 28rem !important;
  max-height: 30rem !important;
  padding-bottom: 0 !important;
}

.donate-overlay {
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 90%;
  height: 90%;
  z-index: 9999;
  animation: fadeInOut 1s ease-in-out;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  overflow: auto;
}

/* Footer Logo Media Query */
@media (max-width: 767px) {
  #logo {
    min-height: 50px;
    width: 25vw;
    min-width: 200px;
  }

  .aim-div{
    display: block;
  }

  .aim-body{
    display: none !important;
  }

  .aim-body-mob {
    display: block
  }

  .aim-head{
    width: 100%;
  }

  .donate-overlay {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .carousel-inner-test {
    min-height: 40rem !important;
    max-height: 40rem !important;
    padding-bottom: 0 !important;
  }

  .carousel-test {
    max-height: 35rem !important;
    min-height: 35rem !important;
  }

  .donate-overlay {
    width: 98%;
  }
}

@media (max-width: 550px) {
  .carousel-inner-test {
    min-height: 50rem !important;
    max-height: 50rem !important;
    padding-bottom: 0 !important;
  }

  .carousel-test {
    max-height: 45rem !important;
    min-height: 45rem !important;
  }

  .medQry-test {
    width: 80% !important;
  }

  .medQry-test > div > img {
    width: 27% !important;
  }
}

@media (max-width: 450px) {
  .carousel-inner-test {
    min-height: 63rem !important;
    max-height: 63rem !important;
    padding-bottom: 0 !important;
  }

  .carousel-test {
    max-height: 63rem !important;
    min-height: 63rem !important;
  }
}

@media (max-width: 330px) {
  .carousel-inner-test {
    min-height: 65rem !important;
    max-height: 65rem !important;
    padding-bottom: 0 !important;
  }

  .carousel-test {
    max-height: 65rem !important;
    min-height: 65rem !important;
  }

  .sub-button button {
    font-size: 1rem;
  }
}

.tstmn-align {
  margin-top: 30px;
}

.first-img-testimonials {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border: 7px solid red;
  margin-right: 100px;
  width: 40%;
}

/* .blog-bg {
  position: relative;
  background-size: cover;
  background-image: url("../public/myImages/photo-tree.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(4, 4, 83, 0.7);
  background-blend-mode: color;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
} */

/* Contact Us Page Media Queries */
@media (max-width: 600px) {
  #contact-us-id > div > div:nth-of-type(1) {
    margin-top: 40px;
  }
}

@media (max-width: 430px) {
  #contact-us-id > div > div:nth-of-type(2) {
    width: 90%;
  }
}

.about-us > h1:nth-of-type(1) {
  font-size: 40px;
}
