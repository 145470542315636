.each__blog {
  max-width: 320px;
  background-color: white;
}

.img__container {
  width: 30%;
  min-width: 120px;
  height: 100px;
}

.blog__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
