.container-g {
    width: 90%;
    margin: 1rem auto;
}

.gallery-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: .7rem;
}

.gallery__item {
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 1px 2px 4px grey;
    height: 105px;
}

.gallery__img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 3px;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin:auto;
    display: flex;
    justify-content: center;
}

.video-responsive iframe {
    top: 0;
    height: 85%;
    width: 85%;
    position: absolute;
}

@media (min-width: 600px) {
    .gallery-2 {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(31, 5.5vw);
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
    }

    .gallery__item {
        overflow: hidden;
        border-radius: 3px;
        box-shadow: 1px 2px 4px grey;
        height: 100%;
    }

    .gallery__item--20 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 5;
    }

    .gallery__item--21 {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 5;
    }

    .gallery__item--22 {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 5;
    }

    .gallery__item--23 {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 5;
        grid-row-end: 10;
    }

    .gallery__item--24 {
        grid-column-start: 7;
        grid-column-end: 13;
        grid-row-start: 5;
        grid-row-end: 10;
    }

    .gallery__item--25 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 10;
        grid-row-end: 14;
    }

    .gallery__item--26 {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 14;
    }

    .gallery__item--27 {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 10;
        grid-row-end: 14;
    }

    .gallery__item--28 {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 14;
        grid-row-end: 19;
    }

    .gallery__item--29 {
        grid-column-start: 7;
        grid-column-end: 13;
        grid-row-start: 14;
        grid-row-end: 19;
    }

    .gallery__item--30 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 19;
        grid-row-end: 23;
    }

    .gallery__item--31 {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 19;
        grid-row-end: 23;
    }

    .gallery__item--32 {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 19;
        grid-row-end: 23;
    }

    .gallery__item--33 {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 23;
        grid-row-end: 28;
    }

    .gallery__item--34 {
        grid-column-start: 7;
        grid-column-end: 13;
        grid-row-start: 23;
        grid-row-end: 28;
    }

    .gallery__item--35 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 28;
        grid-row-end: 33;
    }

    .gallery__item--36 {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 28;
        grid-row-end: 33;
    }

    .gallery__item--37 {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 28;
        grid-row-end: 33;
    }
}