.one {
  display: block;
  width: 50px;
  position: absolute;
  z-index: 3;
}

.blog__profile-pic {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  border-radius: 50%;
}

.change-profile-pic {
  width: 20%;
  position: relative;
  bottom: 0;
  z-index: 2;
  left: 25%;
  bottom: 70px;
}

.curved-btn {
  border-radius: 10px;
}

.blog__info {
  display: none;
}

.blog__profile-form {
  display: none;
}

@media (min-width: 750px) {
  .one {
    grid-column: 1;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    max-width: 500px;
    min-width: 250px;
    position: relative;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)
  }

  .blog__profile-pic {
    margin-top: 1.5rem;
    padding: 1.5rem 0;
  }

  .change-profile-pic {
    width: 20%;
    position: relative;
    bottom: 0;
    z-index: 2;
    left: 25%;
    bottom: 70px;
  }

  .blog__info {
    display: block;
  }

  .blog__profile-form {
    display: block;
  }

  .btn__open-profile {
    pointer-events: none;
  }
}

/* Open Profile Styling */

.one-m {
  background-color: rgb(255, 255, 255);
  max-width: 350px;
  min-width: 260px;
  width: 75vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: -400px;
  visibility: hidden;
  z-index: 1010;
  transition: 0.3s ease-in-out;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.profile-show {
  visibility: visible;
  left: 0;
}

.blog__profile-pic-m {
  margin-top: 1.5rem;
  padding: 1.5rem 0;
}

.change-profile-pic-m {
  width: 20%;
  position: relative;
  bottom: 0;
  z-index: 2;
  left: 25%;
  bottom: 70px;
}

.blog__info-m {
  display: block;
}

.blog__profile-form-m {
  display: block;
}

.btn__open-profile-m {
  pointer-events: none;
}