/* Any change made in this css file should be made respectively
in GirlsHangout19 style.css */

.container-g {
  width: 90%;
  margin: 1rem auto;
}

.gallery-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: .7rem;
}

.gallery__item {
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 1px 2px 4px grey;
  height: 105px;
}

.gallery__img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 3px;
}

@media (max-width: 600) {
  .gallery-1 {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(18, 5.5vw);
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
  }

  .gallery__item {
    height: 100%;
  }

  .gallery__item--10 {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  .gallery__item--11 {
    grid-column-start: 6;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .gallery__item--12 {
    grid-column-start: 8;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .gallery__item--13 {
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 8;
  }

  .gallery__item--14 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 9;
  }

  .gallery__item--15 {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 10;
  }

  .gallery__item--16 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 9;
    grid-row-end: 12;
  }

  .gallery__item--17 {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 10;
    grid-row-end: 12;
  }

  .gallery__item--18 {
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 8;
    grid-row-end: 12;
  }

  .gallery__item--19 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 12;
    grid-row-end: 19;
  }
}

@media (max-width: 768) {}