.login__main{
    height: 100vh;
    background-color: #dedede;
}

.login__box {
    padding: 5vh 5vw;
    background-color: white;
    box-shadow: 2px 4px 10px rgb(185, 185, 185);
}

.login__input__div > input {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    width: 19rem;
}

/* .login__input__div > label {
    
} */

.login__input__div > input:focus {
    border-color: #fac226;
}

.login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 5px;
    border: none;
    color: white;
    background-color: black;
}

.login__btn__email{
    width: 19.5rem;
}

.login__google {
    background-color: white;
    border: solid 1px grey;
    color: black;
    font-weight: 400;
}

.fa-google {
    color: blue
}

.login__facebook {
    background-color: #3b5998;
    color: white;
    font-weight: 400;
}

.fa-facebook {
    background-color:#3b5998;
    color: white;
}

hr {
    flex-grow: 1;
  }

@media (max-width: 600px) {
    .login__main {
        background-color: white;
    }

    .login__box {
        box-shadow: none;
    }
}