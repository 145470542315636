.blog-wrap {
    max-width: 800px;
    margin: 0 auto;
}

.blog-body {
    background-color: #dedede;
}

#blog-Nav {
    color: #040453;
}

.blog-wrap header {
    text-align: center;
}

.blog-goBack {
    text-decoration: none;
    font-size: .8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
}

.blog-date {
    font-size: 0.8rem;
    color:#a9a9a9;
    font-weight: 500;
}

.blog-wrap img {
    width: 100%;
}
.blog-subCategory {
    display: flex;
    justify-content: center;
}

.blog-subCategory  > div{
    margin: 1rem;

}
.blog-desc {    
    padding: 1rem;
    margin-top: 1.5rem;
    text-align: left;
}

.blog-go-back {
    color: #040453;
}

.blog-go-back:hover {
    color: #ff0000;
}