div>header {
    padding-left: 3rem;
    padding-right: 3rem;
}

#courses {
    background-color: #040453;
}

.bg-academy {
    background-image: linear-gradient(to right bottom, #040453, #ff0000 90%);
    height: 100vh;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 93%);
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 93%);
    padding-top: 130px;

    display: flex;
    justify-content: space-between;
}

.academy-w-30 {
    width: 30%;
}

.course-w {
    width: 32%;
}
.course-card {
    border-radius: 20px !important;
}
.course-img {
    border-radius: 20px 20px 0px 0px !important;
}

.btn-yel {
    background-color: #fac226;
}

div>header>div:nth-of-type(2) {
    width: 45%;
    margin-top: 60px;
}

div>header>div:nth-of-type(1)>img {
    box-shadow: 1px 2px 7px grey;
}

div>header>div:nth-of-type(1)>img:nth-of-type(1) {
    z-index: 5;
    position: relative;
    top: -10px;
    width: 300px;
}

div>header>div:nth-of-type(1)>img:nth-of-type(1):hover {
    transition: .3s ease-in-out;
    box-shadow: 4px 4px 10px black;
    transform: scale(1.25);
    z-index: 7;
}

div>header>div:nth-of-type(1)>img:nth-of-type(2) {
    z-index: 4;
    position: relative;
    left: 20px;
    width: 300px;
}

div>header>div:nth-of-type(1)>img:nth-of-type(2):hover {
    transition: .3s ease-in-out;
    box-shadow: 4px 4px 10px black;
    transform: scale(1.15);
    z-index: 7;
}

div>header>div:nth-of-type(1)>img:nth-of-type(3) {
    z-index: 6;
    position: relative;
    top: -40px;
    width: 350px;
}

div>header>div:nth-of-type(1)>img:nth-of-type(3):hover {
    transition: .3s ease-in-out;
    box-shadow: 4px 4px 10px black;
    transform: scale(1.20);
    z-index: 6;
}

.courses-scale:hover {
    transform: scale(1.2);
    transition: all .5s ease-in-out;
}

.foundation-academy {
    display: flex;
    justify-content: space-between;
}

.w-76 {
    width: 75%;
    font-size: 1.2rem;
    text-align: left;
}

.w-77 {
    font-size: 1.1rem;
}

.carousel-acad {
    min-height: 33rem !important;
    max-height: 33rem !important;
}

.carousel-inner-acad {
    min-height: 31rem !important;
    max-height: 31rem !important;
}

.w-60 {
    max-width: 42.5%;
}

.cert {
    padding-top: 2rem;
}

.academy-register:hover {
    box-shadow: 0 0 5px 7px #fac126c0 !important;
}

.academy-learn-more {
    background-color: transparent;
    border: solid 2px #fac226;
}

.academy-learn-more:hover {
    box-shadow: 0 0 5px 7px #fac126c0 !important;
}

@media (max-width: 1080px) {
    div>header>div:nth-of-type(1)>img:nth-of-type(1) {
        left: -30px;
        width: 180px;
    }
    
    div>header>div:nth-of-type(1)>img:nth-of-type(2) {
        left: 0;
        width: 180px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(3) {
        width: 280px;
    }

    div>header>div:nth-of-type(2) {
        width: 90%;
        margin: auto;
    }

    div>header>div:nth-of-type(2)>p {
        width: 100% !important;
    }

    .w-76 {
        width: 95%;
        font-size: 1.2rem;
        text-align: left;
    }

    .carousel-acad {
        min-height: 29rem !important;
        max-height: 29rem !important;
    }

    .w-60 {
        max-width: 54%;
    }
}

@media (max-width: 850px) {
    .bg-academy{
        display: block;
        height: 800px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(1) {
        left: -20px;
        width: 230px;
    }
    
    div>header>div:nth-of-type(1)>img:nth-of-type(2) {
        left: 0;
        width: 230px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(3) {
        width: 300px;
    }

    .carousel-acad {
        min-height: 28.1rem !important;
        max-height: 28.1rem !important;
    }

    .w-60 {
        max-width: 67%;
    }
}

@media (max-width: 770px) {
    .foundation-academy {
        display: block;
    }

    .academy-w-30 {
        width: 90%;
        margin: auto;
    }

    .carousel-acad {
        min-height: 26.1rem !important;
        max-height: 26.1rem !important;
    }

    .w-60 {
        max-width: 67%;
    }
    .course-w {
        width: 100%;
    }
}
    

@media (max-width: 600px) {
    div>header {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bg-academy {
        display: block;
        height: 750px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
    }

    .cert {
        padding-top: 2rem;
    }

    #slider-img {
        padding-top: 0rem;
    }

    .carousel-acad {
        min-height: 20.3rem !important;
        max-height: 20.3rem !important;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(1) {
        left: -10px;
        width: 170px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(2) {
        left: 10px;
        width: 170px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(3) {
        top: -25px;
        left: 10px;
        width: 250px;
    }

    .carousel-acad {
        min-height: 25rem !important;
        max-height: 25rem !important;
    }

    .carousel-inner-acad {
        min-height: 28rem !important;
        max-height: 30rem !important;
    }

    .w-60 {
        max-width: 70%;
    }
    .course-w {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .bg-academy {
        height: 900px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(1) {
        left: -10px;
        width: 160px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(2) {
        left: 25px;
        top: -20px;
        z-index: 6;
        width: 160px;
    }

    div>header>div:nth-of-type(1)>img:nth-of-type(3) {
        top: -30px;
        left: -5px;
        width: 200px;
    }
    .course-w {
        width: 100%;
    }
}

@media (max-width: 320px) {
    .bg-academy {
        height: 950px;
    }
    .course-w {
        width: 100%;
    }
}